import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './style.css';
import { LocationTooltip } from '../location-tooltip';
import { LocationFloorPlan } from '../../../location-floor-plan';
import { BubblePinHeadWithTooltip } from '../../../../components/interactable-pin';
import { useXemelgoClient } from '../../../../services/xemelgo-service';
import { Div } from '../../../../components/div';
import { getDistinctLocationCategories } from './get-distinct-location-categories';
import { getAssetsGroupByLocations } from '../../../../services/asset-service';
import { getLocationStats } from './get-location-stats';
import { useHistory } from 'react-router';

// Constant
const BubbleSizeThreshold = 50;
/**
 * Configuration for this feature. The default configuration is provided in PropTypes.defaultProps
 * @typedef { object } FeatureConfiguration
 * @property { string[] } itemTypes - inspect the item statistics for the given item types.
 * @property { string[] } itemStats - inspect the item statistics for given statistics keywords.
 * @property { string } pinnedLocationCategoryName - the category name of the pinned location. Only one category is allowed.
 */

/**
 * This feature leverage the Location Floor Plan feature, with an additional distinctive features which are to
 *  provide location statistics in a form of tooltip when hovering over the location.
 * @param className { string } - given styling class for the floor plan component.
 * @param location { object } - location object
 * @param displayTooltipImage { boolean } - to determine whether to render location image when available
 * @param imageUrl { string } - floor plan image for given location
 * @param configuration { FeatureConfiguration }
 * @param getLocationPinColorFn { Function }
 * @returns {*}
 */
export const LocationFloorMapWithTooltip = ({
  className,
  location,
  displayTooltipImage,
  imageUrl,
  configuration,
  getLocationPinColorFn,
  onPinClick,
}) => {
  const [locationStatsMap, registerLocationStatsMap] = useState({});
  const [client] = useState(useXemelgoClient());
  const [dataLoaded, setDataLoaded] = useState(false);
  const history = useHistory();
  /**
   *
   */
  useEffect(() => {
    const locationClient = client.getLocationClient();
    const { pinnedLocationCategoryName } = configuration;
    locationClient.getLocationsOfCategory(pinnedLocationCategoryName).then(async (response) => {
      const childrenLocationCategories = getDistinctLocationCategories(response);
      const assetsByLocations = await getAssetsGroupByLocations(client, childrenLocationCategories);
      const statsMap = getLocationStats(assetsByLocations);

      registerLocationStatsMap(statsMap);
      setDataLoaded(true);
    });
  }, [client, location, configuration]);

  const buildPinFn = useCallback(
    (id, name, color, loc) => {
      const locationStats = locationStatsMap[id];
      const tooltip = (
        <LocationTooltip
          key={id}
          location={loc}
          resourceInfoList={[locationStats]}
          displayImage={displayTooltipImage}
        />
      );

      const value = locationStats.total;
      const scale =
        Math.round(value / BubbleSizeThreshold + (value % BubbleSizeThreshold) * 0.1) * 0.3 + 1;
      const pinColor = getLocationPinColorFn(loc, locationStats, color);
      const pin = (
        <BubblePinHeadWithTooltip
          name={value.toString()}
          color={pinColor}
          tooltip={tooltip}
          bubbleScale={scale}
          defaultTextColor="white"
          onClick={() => onPinClick(id)}
        />
      );

      return pin;
    },
    [locationStatsMap, configuration, location, getLocationPinColorFn, history]
  );

  if (dataLoaded) {
    return (
      <LocationFloorPlan
        location={location}
        imageUrl={imageUrl}
        className={`location-floor-map-with-tooltip ${className}`}
        buildPinFn={buildPinFn}
        pinType="badge"
      />
    );
  }

  return <Div />;
};

LocationFloorMapWithTooltip.defaultProps = {
  className: '',
  configuration: {
    itemTypes: ['Asset'],
    itemStats: ['total', 'missing', 'expired'],
    pinnedLocationCategoryName: 'Zone',
  },
  displayTooltipImage: false,
  getLocationPinColorFn: (location, stats, defaultColor) => defaultColor,
  onPinClick: () => {},
};

LocationFloorMapWithTooltip.propTypes = {
  configuration: PropTypes.shape({
    itemTypes: PropTypes.arrayOf(PropTypes.string),
    itemStats: PropTypes.arrayOf(PropTypes.string),
    pinnedLocationCategoryName: PropTypes.string,
  }),
  location: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  displayTooltipImage: PropTypes.bool,
  imageUrl: PropTypes.string.isRequired,
  className: PropTypes.string,
  getLocationPinColorFn: PropTypes.func,
  onPinClick: PropTypes.func,
};
