import ConfigurationService from 'services/ConfigurationService';
import moment from 'moment';
import 'moment-timezone';
import queryString from 'query-string';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import AuthService from '../services/AuthService';
import CONFIGURATION from '../configuration.json';

const aws = require('aws-sdk');

export function findThreshold(itemType, rulesList = [], locationId) {
  let threshold = 0;
  for (const rule of rulesList) {
    if (rule.tags && rule.tags.itemType && itemType === rule.tags.itemType) {
      if (locationId && locationId === rule.tags.locationId) {
        threshold += rule.tags.threshold;
      }
    }
  }
  return threshold || null;
}

export function getDaysToMs(days) {
  return days * (1000 * 60 * 60 * 24);
}

export function parseQueryString(rawQueryString) {
  return queryString.parse(rawQueryString, {
    parseNumbers: true,
    parseBooleans: true
  });
}

export function stringifyToQueryString(object) {
  return queryString.stringify(object, { skipNull: true });
}

export function getSimpleName(fullName) {
  // if has white space
  if (/\s/g.test(fullName)) {
    const matches = fullName.match(/\b(\w)/g); // ['J','S','O','N']
    const acronym = matches.join('');
    return acronym;
  }

  if (fullName.length > 6) {
    return fullName.substring(0, 3);
  }
  return fullName;
}

export function pluralizeWord(word) {
  let returnWord = word;
  const lastLetter = returnWord.charAt(returnWord.length - 1);
  if (lastLetter === 'y') {
    returnWord = `${returnWord.substring(0, returnWord.length - 1)}ies`;
  } else {
    returnWord = `${returnWord}s`;
  }
  return returnWord;
}

export function getFormattedDate(date, format) {
  if (!date) {
    return 'N/A';
  }
  const finalFormat = format || 'MM/DD/YYYY HH:mm:ss';
  return moment(date).format(finalFormat);
}

export function getTimezone() {
  const timezone = moment.tz.guess();
  const date = new Date();
  const timezoneOffset = date.getTimezoneOffset();
  return moment.tz.zone(timezone).abbr(timezoneOffset);
}

export function convertNullValueToDashes(obj, key) {
  return obj[key] ? obj[key] : '--';
}

// The format of dateCell is mm/dd/yyyy hh:mm:ss
export function dateCellToDate(dateCell) {
  return new Date(dateCell);
}

export function dateComparatorSort(dateCell1, dateCell2) {
  // In the example application, dates are stored as mm/dd/yyyy hh:mm:ss
  // We create a Date object for comparison against the filter date
  if (dateCell2 == null) return -1;
  if (dateCell1 == null) return 1;

  const date1 = dateCellToDate(dateCell1);
  const date2 = dateCellToDate(dateCell2);

  // Now that both parameters are Date objects, we can compare
  if (date2 < date1) {
    return -1;
  }
  if (date2 > date1) {
    return 1;
  }
  return 0;
}

export function dateComparatorFilter(filterLocalDateAtMidnight, cellValue) {
  // In the example application, dates are stored as mm/dd/yyyy  hh:mm:ss
  // We create a Date object for comparison against the filter date
  if (cellValue == null) return -1;
  const cellDate = dateCellToDate(cellValue);

  // Now that both parameters are Date objects, we can compare
  if (cellDate < filterLocalDateAtMidnight) {
    return -1;
  }
  if (cellDate > filterLocalDateAtMidnight) {
    return 1;
  }
  return 0;
}

export function uploadImageToS3(payload) {
  // the bucket is currently public. Will change with another pr
  aws.config.region = CONFIGURATION.s3_customer_images_configuration.region;
  // await uploadToS3(payload);
  return AuthService.retrieveEssentialCredentials().then(async (credentials) => {
    aws.config.update({
      credentials: new aws.Credentials(
        credentials.accessKeyId,
        credentials.secretAccessKey,
        credentials.sessionToken
      )
    });
    const s3 = new aws.S3();
    // this call might fail if the credentials expire (should happen very rarely)
    // a future task would be to implment some sort of retrying function
    const result = await s3.putObject(payload).promise();
    return result;
  });
}

export async function uploadToS3(payload) {
  aws.config.region = CONFIGURATION.s3_report_configuration.region;
  AuthService.retrieveEssentialCredentials().then(async (credentials) => {
    aws.config.update({
      credentials: new aws.Credentials(
        credentials.accessKeyId,
        credentials.secretAccessKey,
        credentials.sessionToken
      )
    });
    const s3 = new aws.S3();
    // this call might fail if the credentials expire (should happen very rarely)
    // a future task would be to implment some sort of retrying function
    const result = await s3.putObject(payload).promise();
    return result;
  });
}

export async function listItemsFromS3(bucket, setData, prefix, continuationToken) {
  aws.config.region = CONFIGURATION.s3_report_configuration.region;
  const params = {
    Bucket: bucket,
    Prefix: prefix
  };

  if (continuationToken) {
    params.ContinuationToken = continuationToken;
  }

  const credentials = await AuthService.retrieveEssentialCredentials();
  aws.config.update({
    credentials: new aws.Credentials(
      credentials.accessKeyId,
      credentials.secretAccessKey,
      credentials.sessionToken
    )
  });
  const s3 = new aws.S3();
  await s3.listObjectsV2(params, (err, data) => {
    setData(data);
  });
}

// Currently for the older formats, we remove based on the underscore. When audit and the other reports
// get refactored, this should be removed and everything should use the new file format
const formatFileName = (fileName) => {
  const splitString = fileName.split('/');
  return splitString[splitString.length - 1];
};

export async function getItemFromS3(bucket, key) {
  aws.config.region = CONFIGURATION.s3_report_configuration.region;
  const params = {
    Bucket: bucket,
    Key: key
  };

  const credentials = await AuthService.retrieveEssentialCredentials();
  aws.config.update({
    credentials: new aws.Credentials(
      credentials.accessKeyId,
      credentials.secretAccessKey,
      credentials.sessionToken
    )
  });
  const s3 = new aws.S3();
  await s3.getObject(params, (err, data) => {
    const formattedFileName = formatFileName(key);
    const blob = new Blob([data.Body], { type: data.ContentType });
    FileSaver.saveAs(blob, formattedFileName);
  });
}

export function exportCsv(body, headers, fileName) {
  const fileType = 'text/csv';
  const temp = XLSX.utils.json_to_sheet(body, headers);
  const wb = { Sheets: { data: temp }, SheetNames: ['data'] };
  const excelBuffer = XLSX.write(wb, { bookType: 'csv', type: 'array' });
  const file = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(file, fileName);
}

export function getCustomerLogo() {
  const images = importAllImages(require.context('../img', false, /(_logo)\.(png)$/));
  return ConfigurationService.getCustomerName().then((customerName) => {
    const imageName = `${customerName}_logo.png`;
    return images[`${imageName}`];
  });
}
export function getSmallCustomerLogo() {
  const images = importAllImages(require.context('../img', false, /(_small)\.(png)$/));
  return ConfigurationService.getCustomerName().then((customerName) => {
    const imageName = `${customerName}_small.png`;
    return images[`${imageName}`];
  });
}

export function importAllImages(r) {
  const images = {};
  r.keys().forEach((item) => {
    images[item.replace('./', '')] = r(item);
  });
  return images;
}

export function sortResources(resourceList) {
  return resourceList.sort(function(a, b) {
    if (a.resourceParent === null && b.resourceParent === null) {
      return a.resourceName.localeCompare(b.resourceName);
    }
    if (a.resourceParent === null) {
      return -1;
    }
    if (b.resourceParent === null) {
      return 1;
    }

    if (a.resourceParent.name !== b.resourceParent.name) {
      return a.resourceParent.name.localeCompare(b.resourceParent.name);
    }

    // parents were equal, compare names instead
    return a.resourceName.localeCompare(b.resourceName);
  });
}

export function msToTime(duration) {
  const seconds = parseInt(Math.floor((duration / 1000) % 60), 10);

  const minutes = parseInt(Math.floor(duration / (1000 * 60)) % 60, 10);

  const hours = parseInt(Math.floor((duration / (1000 * 60 * 60)) % 24), 10);

  const days = parseInt(Math.floor((duration / (1000 * 60 * 60 * 24)) % 31), 10);

  const months = parseInt(Math.floor((duration / (1000 * 60 * 60 * 24 * 31)) % 12), 10);

  const years = parseInt(Math.floor(duration / (1000 * 60 * 60 * 24 * 31 * 12)), 10);

  let time = '';
  if (years > 0) {
    time += `${years}y `;
  }
  if (months > 0) {
    time += `${months}mo `;
  }
  if (days > 0) {
    time += `${days}d `;
  }
  if (hours > 0) {
    time += `${hours}h `;
  }
  if (minutes > 0) {
    time += `${minutes}m `;
  }
  if (seconds > 0 || time === '') {
    time += `${seconds}s`;
  }

  return time;
}

export function getNumberOfDaysToDate(date) {
  return moment(moment(date).format('YYYY-MM-DD')).diff(
    moment(moment().format('YYYY-MM-DD')),
    'days'
  );
}

export function getCurrentTimestamp() {
  const currentDate = new Date();
  const currentTimestamp = currentDate.getTime();

  return currentTimestamp;
}
export function findDrillDownValue(object, keys) {
  let mainObject = object;
  let returnVal = '';
  for (const key of keys) {
    if (mainObject[key]) {
      returnVal = mainObject[key];
      mainObject = mainObject[key];
    } else {
      return '';
    }
  }
  return returnVal;
}

export function groupItemsByPropertyValue(itemList, property) {
  const groupedItemsMap = {};

  itemList.forEach((item) => {
    if (item[property]) {
      if (!groupedItemsMap[item[property]]) {
        groupedItemsMap[item[property]] = [item];
      } else {
        groupedItemsMap[item[property]].push(item);
      }
    }
  });

  return groupedItemsMap;
}

export function sortItemsByNumericPropertyValue(itemList, property, sortMode = 'ascending') {
  let sortedList = [];
  switch (sortMode) {
    case 'ascending':
      sortedList = itemList.sort((a, b) => parseInt(a[property]) - parseInt(b[property]));
      break;
    case 'descending':
      sortedList = itemList.sort((a, b) => parseInt(b[property]) - parseInt(a[property]));
      break;
    default:
      break;
  }

  return sortedList;
}

export function genericSort(itemList, property, sortMode = 'ascending') {
  let sortedList = [];
  switch (sortMode) {
    case 'ascending':
      sortedList = itemList.sort((a, b) => (a[property] || '').localeCompare(b[property]));
      break;
    case 'descending':
      sortedList = itemList.sort((a, b) => (b[property] || '').localeCompare(a[property]));
      break;
    default:
      break;
  }

  return sortedList;
}

export function naturalSort(itemList, property, sortMode = 'ascending') {
  let sortedList = [];
  switch (sortMode) {
    case 'ascending':
      sortedList = itemList.sort((a, b) =>
        (a[property] || '').localeCompare(b[property], undefined, {
          numeric: true,
          sensitivity: 'base'
        })
      );
      break;
    case 'descending':
      sortedList = itemList.sort((a, b) =>
        (b[property] || '').localeCompare(a[property], undefined, {
          numeric: true,
          sensitivity: 'base'
        })
      );
      break;
    default:
      break;
  }
  return sortedList;
}

export function sortByPriorityMap(itemList, priorityMap) {
  return itemList.sort((s1, s2) => {
    const p1 = priorityMap[s1] && priorityMap[s1].priority;
    const p2 = priorityMap[s2] && priorityMap[s2].priority;
    return p1 > p2 ? 1 : -1;
  });
}

export function splitArray(arr, chunkSize) {
  const chunks = [];
  while (arr.length) {
    const chunk = arr.slice(0, chunkSize);
    chunks.push(chunk);
    arr = arr.slice(chunkSize);
  }
  return chunks;
}

export const AddPageInputTypeMap = {
  DATE_PICKER: 'datepicker',
  INPUT: 'input',
  SEARCH_DROP_DOWN: 'dropdown',
  CHECK_BOX_GROUP: 'checkboxGroup',
  SEARCH_DROP_DOWN_FROM_API: 'dropdownWithOptionsFromAPI'
};

export const BulkUpdateInputTypeMap = {
  DATE_PICKER: 'datepicker',
  INPUT: 'input'
  // For future use
  // SEARCH_DROP_DOWN: 'dropdown',
  // CHECK_BOX_GROUP: 'checkboxGroup',
  // SEARCH_DROP_DOWN_FROM_API: 'dropdownWithOptionsFromAPI'
};

export const validCSVHeaderCheck = (headers, defaultAttributeMap, customAttributeMap) => {
  const getRequiredHeaders = (attributeMaps = []) => {
    const requiredHeadersList = [];
    attributeMaps.forEach((eachMap) => {
      Object.keys(eachMap).forEach((id) => {
        if (
          Object.keys(eachMap).find((each) => {
            return eachMap[each].metaDataOf === id;
          })
        ) {
          return;
        }

        const { metaDataOf } = eachMap[id];
        if (metaDataOf && !eachMap[metaDataOf].required) {
          return;
        }

        if (eachMap[id].required) {
          requiredHeadersList.push(eachMap[id].label);
        }
      });
    });
    return requiredHeadersList;
  };

  let valid = true;
  let errorMessage;

  // check for duplicates headers
  const hasDuplicates = new Set(headers).size !== headers.length;

  // check for required headers
  let hasRequiredHeaders = true;
  const requiredHeaders = getRequiredHeaders([defaultAttributeMap, customAttributeMap]);

  if (headers.length < requiredHeaders.length) {
    hasRequiredHeaders = false;
  } else {
    const temp = {};
    headers.forEach((element, index) => {
      temp[element] = index;
    });
    hasRequiredHeaders = requiredHeaders.every((element) => {
      return temp[element] !== undefined;
    });
  }

  if (hasDuplicates) {
    valid = false;
    errorMessage =
      'Cannot import csv file: Please check the headers in the csv file for duplicates and upload again';
  } else if (!hasRequiredHeaders) {
    valid = false;
    errorMessage =
      'Cannot import csv file: Please provide the required headers in the csv file and upload again';
  }
  return { valid, errorMessage };
};

export const validCSVDataCheck = (data, defaultAttributeMap, customAttributeMap) => {
  let valid = true;
  let errorMessage;
  data.forEach((each) => {
    Object.keys(each).forEach((id) => {
      const attributeMap = defaultAttributeMap[id] || customAttributeMap[id];
      if (attributeMap) {
        const { type, label, required } = attributeMap;
        if (each[id]) {
          switch (type) {
            case AddPageInputTypeMap.DATE_PICKER:
              const date = Date.parse(each[id]);
              if (isNaN(date)) {
                valid = false;
                errorMessage = `Cannot import csv file: ${each[id]} is not a valid date`;
              }
              break;
            case AddPageInputTypeMap.CHECK_BOX_GROUP:
            case AddPageInputTypeMap.SEARCH_DROP_DOWN_FROM_API:
              const { options, validateCSVInputWithOptions = false } = attributeMap;
              if (typeof each[id] === 'string' && validateCSVInputWithOptions) {
                const inputValue = each[id].trim().toUpperCase();
                if (options && options.length) {
                  if (
                    !options.find(
                      (option) =>
                        (option.label && option.label.trim().toUpperCase() === inputValue) ||
                        (option.valueToValidateWith &&
                          option.valueToValidateWith.trim().toUpperCase() === inputValue)
                    )
                  ) {
                    valid = false;
                    errorMessage = `Invalid ${label} - ${each[id]}`;
                  }
                }
              }
              break;
            case AddPageInputTypeMap.SEARCH_DROP_DOWN:
            case AddPageInputTypeMap.INPUT:
            default:
              break;
          }
        } else if (required) {
          valid = false;
          errorMessage = `Missing field: ${label} is required`;
        }
      } else {
        valid = false;
        errorMessage = 'Cannot import csv file: Please check the provided data';
      }
    });
  });

  return { valid, errorMessage };
};
export function getStackedXemelgoLogo(style) {
  switch (style) {
    case 'dark':
      return 'https://xemelgo-software-images.s3-us-west-2.amazonaws.com/xemelgo-logos/stacked_xemelgo_dark.png';
    case 'light':
    default:
      return 'https://xemelgo-software-images.s3-us-west-2.amazonaws.com/xemelgo-logos/stacked_xemelgo_light.png';
  }
}

export function getLongXemelgoLogo() {
  return 'https://xemelgo-software-images.s3-us-west-2.amazonaws.com/xemelgo-logos/long_xemelgo.png';
}

export async function getLogo() {
  const customerLogo = await ConfigurationService.defaultCustomerLogo();
  if (!customerLogo) {
    return getLongXemelgoLogo();
  }
  return customerLogo;
}

export function getStatusFlags(statuses = [], availableFlags = {}) {
  const statusFlags = statuses.reduce((result, status) => {
    // Skip duplicates
    if (result.find((r) => r.id.toLowerCase() === status.toLowerCase())) {
      return result;
    }

    if (availableFlags[status]) {
      result.push({ id: status, ...availableFlags[status] });
    } else if (availableFlags[status.toLowerCase()]) {
      // TO DO: Update order-client to use getOrderStatusFlag func so we don't have to check casing here
      result.push({ id: status.toLowerCase(), ...availableFlags[status.toLowerCase()] });
    }
    return result;
  }, []);
  return statusFlags;
}

export function getTimeLeftText(date) {
  let value = '';
  let color = '';
  if (date) {
    const numOfDaysLeft = getNumberOfDaysToDate(date);
    value =
      numOfDaysLeft > 0
        ? numOfDaysLeft === 1
          ? `In ${numOfDaysLeft} day`
          : `In ${numOfDaysLeft} days`
        : numOfDaysLeft === 0
        ? 'Today'
        : numOfDaysLeft === -1
        ? `${Math.abs(numOfDaysLeft)} day ago`
        : `${Math.abs(numOfDaysLeft)} days ago`;

    color = numOfDaysLeft > 0 ? 'green' : 'red';
  }
  return { value, color };
}

export const getAttributeValue = (attributeConfig, item, attribute) => {
  const { tenantPropertyFor, defaultValue = '-' } = attributeConfig;
  const { customFields, type, state } = item;
  const { customFields: typeCustomFields } = type;
  switch (tenantPropertyFor) {
    case 'itemTypes':
      return type[attribute] || typeCustomFields[attribute] || defaultValue;
    default:
      let attributeValue = item[attribute] || customFields[attribute] || defaultValue;
      if (attribute === 'last_known_location' && attributeValue !== defaultValue && state) {
        attributeValue += ` - ${state.display}`;
      }
      return attributeValue;
  }
};

export const getAttributeList = (attributeConfig = {}, item = {}) => {
  const { customFields } = item;
  let defaultMap = attributeConfig.defaultAttributeMap;
  let customMap = attributeConfig.customAttributeMap;

  defaultMap = Object.keys(defaultMap).map((eachId) => {
    const { type, convertFromMilliseconds } = defaultMap[eachId];
    return {
      index: defaultMap[eachId].index,
      id: eachId,
      key: eachId,
      name: `${defaultMap[eachId].label}`,
      type,
      value: getAttributeValue(defaultMap[eachId], item, eachId),
      editable: defaultMap[eachId].editable.single,
      replaceable: defaultMap[eachId].replaceable,
      format: defaultMap[eachId].format,
      relativeDayCount:
        defaultMap[eachId].showRelativeDayCount && (type === 'date' || type === 'datepicker')
          ? {
              ...getTimeLeftText(item[eachId]),
              show: defaultMap[eachId].showRelativeDayCount
            }
          : {},
      convertFromMilliseconds
    };
  });

  customMap = Object.keys(customMap).map((eachId) => {
    const { type, convertFromMilliseconds } = customMap[eachId];
    return {
      index: customMap[eachId].index,
      id: eachId,
      key: eachId,
      name: `${customMap[eachId].label}`,
      type,
      value: customFields[eachId],
      editable: defaultMap[eachId].editable.single,
      format: defaultMap[eachId].format,
      relativeDayCount:
        defaultMap[eachId].showRelativeDayCount && (type === 'date' || type === 'datepicker')
          ? {
              ...getTimeLeftText(item[eachId]),
              show: defaultMap[eachId].showRelativeDayCount
            }
          : {},
      convertFromMilliseconds
    };
  });

  let attributeList = [...defaultMap, ...customMap];
  attributeList = attributeList.sort((a, b) => {
    return a.index - b.index;
  });

  return attributeList;
};

// this will format a number to the system locale and the currency type
export function formatCurrency(numberToFormat, currencyType = 'USD', decimalPlaces) {
  return Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: currencyType,
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces
  }).format(numberToFormat);
}

export function formatNumber(numberToFormat, decimalPlaces) {
  return Intl.NumberFormat(undefined, {
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces
  }).format(numberToFormat);
}

export function getValueOrDefault(getValueFn = () => {}, defaultValue = null) {
  try {
    return getValueFn();
  } catch {
    return defaultValue;
  }
}

export function convertAsciiToHex(asciiString) {
  const arr1 = [];
  if (asciiString === null || asciiString === undefined) {
    return null;
  }
  for (let n = 0, l = asciiString.length; n < l; n++) {
    const hex = Number(asciiString.charCodeAt(n)).toString(16);
    arr1.push(hex);
  }
  return arr1.join('');
}

export function isValidEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email));
}

export function imageValidityCheck(url, callback) {
  const http = new XMLHttpRequest();
  http.open('HEAD', url);
  http.onreadystatechange = function() {
    if (this.readyState == this.DONE) {
      // check if the link is valid and file exists
      if (this.status !== 403) {
        // check if file is an image
        isImage(url, callback);
      } else {
        callback(false);
      }
    }
  };
  http.send();
}

export function isImage(src, callback) {
  const img = new Image();
  img.onload = function() {
    callback(true);
  };
  img.onerror = function() {
    // doesn't exist or error loading
    callback(false);
  };
  img.src = src;
}
