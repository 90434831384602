const pinMap = {
  // 'dabfa12a-f910-0253-2b31-792c342642ff': {
  //   x: 1650,
  //   y: 440,
  //   color: 'green'
  // },
  // '5cbfa12a-d522-5067-f012-f28cc40c12b5': {
  //   x: 1120,
  //   y: 1730,
  //   color: 'green'
  // },
  // '58bfa12a-ab50-181e-7407-68ec70b25f74': {
  //   x: 350,
  //   y: 300,
  //   color: '#f20202'
  // }
  // '5cc7e05c-130e-541c-25c2-221906e39e10': {
  //   x: 2180,
  //   y: 1550,
  //   color: 'green'
  // },
  // '80c7e05c-1310-3f0b-263a-b6b14a02349c': {
  //   x: 1500,
  //   y: 1200,
  //   color: 'green'
  // },
  // '52c7e05c-1327-ce90-a86a-472d505bfdf8': {
  //   x: 450,
  //   y: 780,
  //   color: '#f20202'
  // }

  'ecc80459-5206-46ae-aa30-c43ffd5ee2be': {
    // Exit 1
    x: 500,
    y: 100,
    color: '#f20202'
  },
  'e6c80459-522a-f210-a916-6f33f48c515e': {
    // Exit 2
    x: 950,
    y: 100,
    color: '#f20202'
  },
  'bec80459-523b-5be1-1819-c41dc9a6d75c': {
    // Exit 3
    x: 1500,
    y: 100,
    color: '#f20202'
  },
  '38c80459-5212-463e-59c5-2cff510fa6e0': {
    // Zone 7
    x: 500,
    y: 900,
    color: 'green'
  },
  'fac80459-5216-a417-046f-06c838618e7c': {
    // Encoding Stations
    x: 1250,
    y: 900,
    color: 'green'
  },
  '2ec80459-52a4-33d6-6b9e-b19c3aaf49ed': {
    // Zone 2
    x: 2200,
    y: 900,
    color: 'green'
  },
  'e8c80459-5224-7820-542d-eb31f54c2d9a': {
    // Zone 8
    x: 300,
    y: 1250,
    color: 'green'
  },
  '2cc80459-5205-d3bd-0899-3ba620a43321': {
    // Zone 6
    x: 850,
    y: 1250,
    color: 'green'
  },
  '2ec80459-5224-7dae-6ffe-d4ce89063870': {
    // Zone 5
    x: 1300,
    y: 1250,
    color: 'green'
  },
  '42c80459-520c-4035-0565-0a6e706cc184': {
    // Zone 4
    x: 1600,
    y: 1250,
    color: 'green'
  },
  '76c80459-522a-53f0-8f19-2ed81afee1da': {
    // Zone 3
    x: 1850,
    y: 1250,
    color: 'green'
  },
  '54c80459-5216-ba2a-b1c6-1322ee1bf984': {
    // Zone 1
    x: 2500,
    y: 1250,
    color: 'green'
  }
};

const injectImagePathToResponse = (locationResponse, locationId, injectingImagePath) => {
  const { id } = locationResponse;
  if (id === locationId) {
    // eslint-disable-next-line no-param-reassign
    locationResponse.getImagePath = () => injectingImagePath;
    return locationResponse;
  }

  const parent = locationResponse.getParent();
  if (parent) {
    const modifiedParent = injectImagePathToResponse(parent, locationId, injectingImagePath);
    // eslint-disable-next-line no-param-reassign
    locationResponse.getParent = () => modifiedParent;
    return locationResponse;
  }
  return locationResponse;
};

const injectPinLocationToResponse = (locationResponse) => {
  const { data } = locationResponse;
  const { id } = data;
  const pin = data.pin ?? pinMap[id];

  // eslint-disable-next-line no-param-reassign
  locationResponse.getPin = () => pin;
  data.pin = pin;
  // eslint-disable-next-line no-param-reassign
  locationResponse.data = data;
  return locationResponse;
};

/**
 * @param locationClient
 * @param categoryName
 * @return {Promise<*>}
 */
export const getLocations = (locationClient, categoryName) => {
  return locationClient.getLocationsOfCategory(categoryName).then((responses) => {
    const xformedResponses = responses.map((response) => {
      let modifiedResponse = injectImagePathToResponse(
        response,
        '8abf9d40-3746-b2ee-c42c-6e273d5b66a8',
        // 'https://xemelgo-logo.s3.us-west-2.amazonaws.com/8abf9d40-3746-b2ee-c42c-6e273d5b66a8/B12-2nd-Floor.png'
        //'https://xemelgo-customer-images.s3.us-west-2.amazonaws.com/controltek/NRF+2024+RFID+Demo_Floorplan.jpg'
        'https://xemelgo-customer-images.s3.us-west-2.amazonaws.com/controltek/JCrew+Sample+Library.png'
      );

      modifiedResponse = injectPinLocationToResponse(modifiedResponse);
      return modifiedResponse;
    });

    return xformedResponses;
  });
};

/**
 * @param locationClient
 * @param parentLocationId
 * @return {Promise<*>}
 */
export const getChildrenLocation = (locationClient, parentLocationId) => {
  return locationClient
    .getLocationChildrenByParentId(parentLocationId)
    .then((containedLocations) => {
      return containedLocations.map((loc) => {
        // eslint-disable-next-line no-param-reassign
        loc = injectPinLocationToResponse(loc);
        return loc;
      });
    });
};
