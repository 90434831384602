import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Div } from '../../../../components/div';
import './style.css';

const getResourceDetailView = (resourceInfo) => {
  const { type, stats } = resourceInfo;

  return (
    <Div className="resource-detail" key={type}>
      <Div className="resource-header">{type}</Div>
      <Div className="resource-stat-details">
        {stats.map((stat) => {
          const { name, value } = stat;
          return (
            <Div className="resource-stat-line" key={name}>
              <Div className="resource-stat-name">{name}</Div>
              <Div className="resource-stat-value">{value}</Div>
            </Div>
          );
        })}
      </Div>
    </Div>
  );
};

const renderLocationImage = (locationName, imagePath) => {
  return (
    <Fragment>
      <Div className="tooltip-details">
        <Div className="tooltip-location-detail">
          <Div className="tooltip-location-name">{locationName}</Div>
          <img className="tooltip-image" src={imagePath} />
        </Div>
      </Div>
    </Fragment>
  );
};

export const LocationTooltip = ({ location, resourceInfoList, displayImage = false }) => {
  const { name: locationName, category, imagePath } = location;
  if (displayImage && imagePath) {
    return renderLocationImage(locationName, imagePath);
  }
  return (
    <Fragment>
      <Div className="tooltip-details">
        <Div className="tooltip-location-detail">
          <Div className="tooltip-location-name">{locationName}</Div>
          <Div className="tooltip-location-category">{category}</Div>
        </Div>
        <Div className="tooltip-resources-details">
          {resourceInfoList.map((resourceInfo) => {
            return getResourceDetailView(resourceInfo);
          })}
        </Div>
      </Div>
    </Fragment>
  );
};

LocationTooltip.defaultProps = {
  displayImage: false,
};

LocationTooltip.propTypes = {
  location: PropTypes.shape({
    name: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
  }).isRequired,
  displayImage: PropTypes.bool,
  resourceInfoList: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      stats: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        })
      ).isRequired,
    })
  ).isRequired,
};
