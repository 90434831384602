import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FloorPlan } from '../../floor-plan';
import { GpsPinHead } from '../../interactable-pin/gps-pin-head';

export const AssetOnMap = ({ location }) => {
  const [floorPlanImageUrl, setFloorPlanImageUrl] = useState(undefined);
  const [pins, setPins] = useState([]);
  const [dimensionRatio] = useState({ dx: 1 });

  const buildPin = useCallback(
    (id, name, color) => {
      return <GpsPinHead color={color} scale={dimensionRatio.dx * 3} />;
    },
    [dimensionRatio]
  );

  useEffect(() => {
    let cancelled = false;
    const cancelCb = () => {
      cancelled = true;
    };

    if (!location) {
      return cancelCb();
    }

    const parentLocation = location.getParent();
    if (!parentLocation) {
      return cancelCb();
    }

    const imageUrl = parentLocation.getImagePath();
    if (!imageUrl) {
      return cancelCb();
    }

    if (!cancelled) {
      setFloorPlanImageUrl(imageUrl);
    }

    const locPin = location.getPin();
    if (!locPin) {
      return cancelCb();
    }

    const { id, name } = location;
    const { color } = locPin;
    const pinComponent = buildPin(id, name, color);

    if (!cancelled) {
      setPins([
        {
          id,
          ...locPin,
          component: pinComponent
        }
      ]);
    }

    return cancelCb();
  }, [location]);

  return floorPlanImageUrl ? (
    <FloorPlan imageUrl={floorPlanImageUrl} pins={pins} className="asset-on-floor-map" />
  ) : (
    <>No Floor Plan Available</>
  );
};

AssetOnMap.propTypes = {
  location: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    getImagePath: PropTypes.func,
    getParent: PropTypes.func,
    getPin: PropTypes.func
  }).isRequired
};
